import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { TextArea } = Input;
const { Option } = Select;

const MerchantModal = (props) => {
    const { t, merchantId, isModalVisible, handleCancel, fetchMerchants } = props;
    const [merchant, setMerchant] = useState(null);
    const [loading, setLoading] = useState(false);
    const [providerList, setProviderList] = useState(null);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const [form] = Form.useForm();

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const formValChange = (chgV, allV) => {
        setMerchant({ ...merchant, ...allV });
    };

    useEffect(() => {
        if (merchantId) {
            getMerchantDetail();
        }
        if(!providerList) {
            getProviderList();
        }
    }, [merchantId]);  // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantDetail = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/merchant/${merchantId}/details/`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setMerchant(data);
                form.setFieldsValue(data);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const getProviderList = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/providers`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setProviderList(data);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const generateJWTToken = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/merchant/${merchantId}/generateJWTToken`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                if (data.status === "ok") {
                    setMerchant({ ...merchant, jwtToken: data.token });
                    form.setFieldsValue({ ...merchant, jwtToken: data.token });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const onFinish = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/merchant/${merchantId}/edit/`;

        await axios
            .post(apiUrl, merchant, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.oi.merchant_updated"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchMerchants();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
        setLoading(false);
    };

    const isOkButtonProps = () => !merchant || !merchant.name || loading;

    return (
        <>
            <Modal
                title={t("contents.oi.view_merchant")}
                visible={isModalVisible}
                width={600}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="merchantId" label={t("contents.oi.form.merchant_id")}>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item name="name" label={t("contents.oi.form.name")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="providerId" label={t("contents.oi.form.provider")} rules={[{ required: true }]}>
                        <Select placeholder={t("contents.merchants.form.please_select")}>
                            {providerList
                                ? providerList.map((item) => (
                                        <Option key={item.providerId} value={item.providerId}>
                                            {item.name}
                                        </Option>
                                    ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="jwtToken" label={t("contents.oi.form.jwt_token")} rules={[{ required: true }]}>
                        <TextArea rows={3} readOnly={true} />
                    </Form.Item>
                    <Form.Item {...tailLayout} className="mb-0">
                        <Button htmlType="button" onClick={() => generateJWTToken()}>
                            {t("contents.oi.form.generate_jwt")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MerchantModal;