import React, { useState,useEffect } from "react";
import { Modal, Button, Form, Radio, Select, Input, Spin,Tooltip } from "antd";
import { API, SOCKET_URL, REALTIME_DASHBOARD,API_TXALERT  } from "../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import ArrangeReasons from "../../helper/ArrangeReasons";
import _ from "lodash";
import htmlParser from "html-react-parser";

let domain = new URL(API);


const DATA_URL = "/alert/";
const DATA_GET_RCODE_URL = "/getrcode/";
const API_ALERT_UPDATE_STATUS_URL = "/secure/alert/update/v2/";

//reasons is no longer passed as props. may 22
const ChangeAlertStatusV2 = (props) => {
    const { t, row, outcome, socket, doFetch } = props;
    const [reason, setReason] = useState({ status: "", reasoncode: "", reason_code: "", reason_filter: "001" });
    const [isDuplicateRequest, setIsDuplicateRequest] = useState(false);
    const [isPartiallyStopped, setIsPartiallyStopped] = useState(false);
    const [isAdditonalField, setIsAdditonalField] = useState(false);
    const [isComment, setIsComment] = useState(false);
    const [isValidAlertID, setIsValidAlertID] = useState(false);
    const [isValidAmountRefunded, setIsValidAmountRefunded] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [rcode, setRcode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [reasonList, setReasonList] = useState([]);  //all reasons from api call
    const codesDuplicateRequest = ["940.001", "940.002", "940.003"];
    const [reasons, setReasons] = useState([]); //grouped by status
    const [additionalFieldLable, setAdditonalFieldLable] = useState("");
   
    const [form] = Form.useForm();

    const showModal = () => {
        setIsLoading(true);
        getReasons(row.id);
        
        setIsModalVisible(true);
        getRcode();

    };

    useEffect(() => {
       if( reasonList.length !== 0) {        
        const result = [...reasonList.reduce((r, o) => {
            const key = o.status + '-' + o.description_short_form;
            const item = r.get(key) || Object.assign({}, o, {
                refund_flag: []
            });
            item.refund_flag = [...item.refund_flag, o.refund_flag];
            return r.set(key, item);
        }, new Map()).values()];

        setReasons(_.groupBy(result, "status"));  
        formValInit();
       }
    }, [reasonList]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const formValInit = () => {
        form.setFieldsValue({ status: "", reasoncode: "", reason_code: "", reason_filter: "001" });
        setReason({ status: "", reasoncode: "", reason_code: "", reason_filter: "001" });
        setIsDuplicateRequest(false);
        setIsValidAlertID(false);
        setIsLoading(false);
        setIsPartiallyStopped(false);
        setIsAdditonalField(false);
        setIsComment(false);
    };

    const getReasons = () => {
        const apiUrl = API_TXALERT + `/provideralertreasons`;
        
        axios
            .post(apiUrl, { alertid: row.id,isOnlyDisplayOnDashboard: true }, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                if(data.length !== 0)
                {
                setReasonList(ArrangeReasons(data));          
                }
            })
            .catch((error) => {
                console.log("Alert Reasons", error);
            });
    };

    const checkReasonCode = () => {
        if (reason.reason_code === '901.002')
        {
            Swal.fire({
                title: "The descriptor will be unenrolled",
                text: "Do you want to continue?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Continue",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    handleOk();
                }
            });
        }else{
            handleOk();
        }
    }

    const handleOk = () => {
        const authuser = window.atob(localStorage.getItem("authuser")).split(":");
        const alertid = row.id;
        let data = {
            status: reason.status,
            reason_code: reason.reason_code,
            alert_id: alertid,
            tx_details: { userid: parseInt(authuser[0]), username: authuser[1] },
            alert_outcome_action_date: new Date(),
            alert_outcome_amount: row.transaction_amount,
            alert_outcome_currency: row.transaction_currency,
        };
        if (isDuplicateRequest) {
            data = { ...data, tx_details: { ...data.tx_details, duplicate_alertid: parseInt(reason.duplicate_alertid) } };
        }
        if (isPartiallyStopped) {
            data = { ...data, alert_outcome_amount: parseFloat(reason.partially_stopped_amount) };
        }

        // row.providerclassfile === 'cdrncl' - Replace with row.alert_type === 'Dispute'
        if (isAdditonalField && row.alert_type.toLowerCase() === 'dispute' && reason.additional_field) {
            if(reason.reasoncode === '952')
                data = { ...data, tx_details: { ...data.tx_details, chargeback_reference: parseInt(reason.additional_field) } };
            if(reason.reasoncode === '954')
                data = { ...data, tx_details: { ...data.tx_details, eci_code: parseInt(reason.additional_field) } };
            if(reason.reasoncode === '956')
                data = { ...data, tx_details: { ...data.tx_details, tracking_number: parseInt(reason.additional_field) } };
        }

        // row.providerclassfile === 'ethocacl' - Replace with ['Confirmed Fraud','Customer Dispute'].includes(row.alert_type)
        if (isComment && ['confirmed fraud','customer dispute'].includes(row.alert_type.toLowerCase()) && reason.comment !== '') {
            data = { ...data, comments: reason.comment};
        }

        const options = {
            method: "POST",
            headers: { rcode: rcode, "content-type": "application/json", Authuser: localStorage.getItem("authuser") },
            data: JSON.stringify(data),
            url: domain.origin + API_ALERT_UPDATE_STATUS_URL,
        };

        axios(options)
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.alerts.update_status_successful"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            if (REALTIME_DASHBOARD && SOCKET_URL && socket) {
                                axios
                                    .post(
                                        SOCKET_URL + "/alert",
                                        { alertid: alertid },
                                        { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } }
                                    )
                                    .then((result) => {})
                                    .catch((error) => console.log(error));
                            } else {
                                doFetch();
                            }
                        },
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: data.message,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                let title_error = error.response ? error.response.data.message : "";
                let message_error = `Error Code: ${error.response ? error.response.status : ""}`;

                let index = title_error.indexOf("If the Alert cannot be matched to a transaction in your system");
                if (index !== -1){
                    message_error = "Note: " + title_error.substring(index) + `<br> Error Code: ${error.response ? error.response.status : ""}`;
                    title_error = title_error.substring(0,index);
                }

                Swal.fire({
                    icon: "error",
                    title: title_error,
                    html: message_error,
                    // text: `Error Code: <br> ${error.response ? error.response.status : ""}`,
                    showConfirmButton: true,
                });
            });
        setIsModalVisible(false);
    };

    const getRcode = () => {
        if (row && row.id) {
            const apiUrl = DATA_GET_RCODE_URL;
            try {
                axios
                    .post(API + apiUrl, { alertid: row.id }, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
                    .then(({ data }) => {
                        setRcode(data.rcode)
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                console.log(error);
            }
        }
    };

    const isAlertIdExist = async (id) => {
        const apiUrl = DATA_URL + id;
        if (!id) {
            return false;
        }
        try {
            return await axios
                .get(API + apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
                .then(({ data }) => !!data.parent[0])
                .catch((err) => {
                    return false;
                });
        } catch (error) {
            return false;
        }
    };

    const formValChange = (chgV, allV) => {
        let state = null;

        if (chgV.status) {
            state = { status: chgV.status, reason_filter: reason.reason_filter, reasoncode: "", reason_code: "", description_short_form: "", reason_description: "", duplicate_alertid: "" };
        } else if (chgV.reasoncode) {
            const description = reasons[reason.status].find((obj) => obj.reason_code === chgV.reasoncode).description_short_form || "";
            const reason_description = reasons[reason.status].find((obj) => obj.reason_code === chgV.reasoncode).reason_description || "";
            state = { ...reason, reasoncode: chgV.reasoncode, description_short_form: description, reason_description: reason_description, duplicate_alertid: "" };
            // setIsDuplicateRequest(codesDuplicateRequest.includes(chgV.reason_code));

            let refundflags = reasons[state.status].filter((obj) => obj.description_short_form === state.description_short_form)[0].refund_flag;
            state.reason_filter = refundflags[0];

        }  else if (chgV.reason_filter) {
            state = { ...reason, reason_filter: chgV.reason_filter};
        }else if (chgV.duplicate_alertid) {
            if (Number.isInteger(parseInt(chgV.duplicate_alertid)) && parseInt(chgV.duplicate_alertid) > 0) {
                state = { ...reason, duplicate_alertid: chgV.duplicate_alertid };
            } else {
                state = { ...reason };
            }
        } else if (chgV.duplicate_alertid === "") {
            state = { ...reason, duplicate_alertid: "" };
        } else if (chgV.partially_stopped_amount) {
            if (!Number.isNaN(Number.parseFloat(chgV.partially_stopped_amount)) && Number.parseFloat(chgV.partially_stopped_amount) > 0) {
                state = { ...reason, partially_stopped_amount: chgV.partially_stopped_amount };
            } else {
                state = { ...reason };
            }
        } else if (chgV.partially_stopped_amount === "") {
            state = { ...reason, partially_stopped_amount: "" };
        } else if (chgV.comment) {
            if (chgV.comment !== "") {
                state = { ...reason, comment: chgV.comment };
            } else {
                state = { ...reason };
            }
        }else if (chgV.comment === "") {
            state = { ...reason, comment: "" };
        } else if (chgV.additional_field) {
            if (Number.isInteger(parseInt(chgV.additional_field)) && parseInt(chgV.additional_field) > 0) {
                state = { ...reason, additional_field: chgV.additional_field };
            } else {
                state = { ...reason };
            }
        } else if (chgV.additional_field === "") {
            state = { ...reason, additional_field: "" };
        } else {
            state = { ...reason };
        }

        let reasonCode = '';

        if(state.reasoncode !== ""){
            reasonCode = state.reasoncode;
            if(state.reason_filter)
                reasonCode = reasonCode + '.' + state.reason_filter;
            state.reason_code = reasonCode;
        }

        if(state.reasoncode === '101' && state.description_short_form !== "" && state.reason_filter === '001'){
            setIsPartiallyStopped(true);
        }else{
            setIsPartiallyStopped(false);
        }
        if(state.reasoncode === '900' && row.alert_type.toLowerCase() === "confirmed fraud"){
            setIsComment(true);
        }else{
            setIsComment(false);
        }
        if(row.alert_type.toLowerCase() === 'dispute' && (state.reasoncode === '952' || state.reasoncode === '954' || state.reasoncode === '956') && state.description_short_form !== ""){
            setIsAdditonalField(true);
            if(state.reasoncode === '952')
                setAdditonalFieldLable('chargeback_reference_number');
            if(state.reasoncode === '954')
                setAdditonalFieldLable('eci_code');
            if(state.reasoncode === '956')
                setAdditonalFieldLable('tracking_number');
        }else{
            setIsAdditonalField(false);
        }

        if(reasonCode !== ''){
            setIsDuplicateRequest(codesDuplicateRequest.includes(reasonCode));
        }

        form.setFieldsValue(state);
        setReason(state);
    };

    const getReasonOptions = (arn) =>{
        return reasons[reason.status].map((obj) => (
            (obj.refund_flag.length === 1 && obj.refund_flag[0] === "003" && arn !== '' && arn !== null) 
            ? null :
            <Select.Option key={obj.outcome_response_id} value={obj.reason_code} >
                {/* {setNormalizeText(obj.description_short_form)} */}
                <Tooltip title={htmlParser(obj.reason_description)}>
                {htmlParser(obj.description_short_form)}
                </Tooltip>
            </Select.Option>
        ));
    }
        

    const getButtonName = (outcome,canUpdate) => {
        // rdr matched should not display action required button
        if (!outcome) {
            return t("tables.actions.action_required");
        }
        switch (outcome.toLowerCase()) {
            case "error":
                return t("tables.actions.action_required");
            case "matched":
                 //if matched, it should be updatable as the outcome will change if there was already a push to provider
                //can update is only a valid check for already pushed alerts. 
                return t("tables.actions.action_required"); 
            case "wip":
                return t("tables.actions.wip");
            default:                
                return canUpdate?t("tables.actions.update"):null;
           
        }
    };

    const getTranslation = (reason) => {
        try {
            return t(`outcomes.${reason.toLowerCase()}`);
        } catch (error) {
            return reason;
        }
    };

    const isOkButtonProps = () =>
        !reason.status || !reason.reasoncode 
        || (isDuplicateRequest && !!reason.duplicate_alertid === false) 
        || (isDuplicateRequest && !isValidAlertID) 
        || (isPartiallyStopped && !!reason.partially_stopped_amount === false) 
        || (isPartiallyStopped && !isValidAmountRefunded);

    const getReasonFilter = (arn) => {
        if(reason.status && reason.reasoncode){
            let radioList = reasons[reason.status].filter((obj) => obj.reason_code === reason.reasoncode);
            if(reason.status.toLowerCase() === 'wip')
                return null;
            var refundflags = radioList[0].refund_flag;
            return  refundflags.map((obj) => (
                // <Radio key={obj} value={obj}>{getRefundFlag(obj)}</Radio>
                <Radio key={obj} value={obj} disabled={(obj === '003' && arn !== '' && arn !== null)}>{getRefundFlag(obj)}</Radio>
            ));
        }
    };

    const getRefundFlag = (flag) => {
        switch (flag) {
            case "001":
                return t("contents.alerts.refunded");
            case "002":
                return t("contents.alerts.not_refunded"); 
            case "003":
                return t("contents.alerts.not_settled");
            default:                
                return null;
        }
    };
    
    return (
        <>
            {
            (typeof row.canUpdate === 'undefined' && row?.alert_type)?(
            <Button size="small" onClick={showModal}> 
                {getButtonName(outcome,(row.alert_type?.match("_RDR")?false:true))}
            </Button>
            ):(            
            <Button size="small" onClick={showModal}> 
                {getButtonName(outcome,row.canUpdate)}
            </Button>
            )}
            <Modal
                title={`${t("contents.alerts.update_status")} (${t("contents.alerts.alert_id")} ${row.id})`}
                visible={isModalVisible}
                onOk={checkReasonCode}
                onCancel={handleCancel}
                okButtonProps={{
                    disabled: isOkButtonProps(),
                }}
                width={720}
            >
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center alertupdatestatus-box--spinner">
                        <Spin size="large" />
                    </div>
                ) : (
                    <Form layout="vertical" form={form} onValuesChange={formValChange}>
                       {row.canUpdate && outcome && outcome.toLowerCase() !== 'matched'  ?( <div>{t("contents.alerts.update_warning")}</div>):''}
                        <Form.Item label={t("contents.alerts.outcome")} name="status">
                            <Select >
                                {Object.keys(reasons).map((reason) => (
                                    <Select.Option key={reason} value={reason} >
                                        {getTranslation(reason)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                       
                        <Form.Item label={t("contents.alerts.reason")} name="reasoncode">
                            <Select  disabled={!reason.status}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) => optionA.children??optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                            >
                                {reason.status ? getReasonOptions(row.arn) : null}
                            </Select>
                        </Form.Item>

                        <Form.Item name="reason_filter">
                            <Radio.Group>
                                {getReasonFilter(row.arn)}
                            </Radio.Group>
                        </Form.Item>
                        {isDuplicateRequest ? (
                            <Form.Item
                                label={t("contents.alerts.duplicate_alertid")}
                                name="duplicate_alertid"
                                rules={[
                                    { required: true, message: t("contents.alerts.required_messages.duplicate_alertid_required") },
                                    ({ getFieldValue }) => ({
                                        async validator(rule, value) {
                                            if (!value || (await isAlertIdExist(value))) {
                                                if (row.id === parseInt(value)) {
                                                    setIsValidAlertID(false);
                                                    return Promise.reject("Duplicate Alert ID couldn't be the same as Alert ID");
                                                }
                                                setIsValidAlertID(true);
                                                return Promise.resolve();
                                            }
                                            setIsValidAlertID(false);
                                            return Promise.reject(t("contents.alerts.required_messages.duplicate_alertid_not_valid"));
                                        },
                                    }),
                                ]}
                            >
                                <Input type="number" min="1" className="no-arrow no-scroll" />
                            </Form.Item>
                        ) : null}
                        {isPartiallyStopped ? (
                            <Form.Item
                                label={t("contents.alerts.amount_refunded")}
                                name="partially_stopped_amount"
                                rules={[
                                    { required: true, message: t("contents.alerts.required_messages.amount_refunded_required") },
                                    ({ getFieldValue }) => ({
                                        async validator(rule, value) {
                                            if (!value) {
                                                setIsValidAmountRefunded(false);
                                                return Promise.reject("Amount refunded should not be null");
                                            }else{
                                                if (parseFloat(row.transaction_amount) < parseFloat(value)) {
                                                    setIsValidAmountRefunded(false);
                                                    return Promise.reject("Amount refunded should be less than the transaction amount");
                                                }
                                                setIsValidAmountRefunded(true);
                                                return Promise.resolve();
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input type="number" min="1" className="no-arrow no-scroll" />
                            </Form.Item>
                        ) : null}
                        {isAdditonalField ? (
                            <Form.Item
                                label={t("contents.alerts."+additionalFieldLable)}
                                name="additional_field"
                            >
                                <Input type="number" min="1" className="no-arrow no-scroll" />
                            </Form.Item>
                        ) : null}
                        {isComment ? (
                            <Form.Item
                                label={t("contents.alerts.comment")}
                                name="comment"
                            >
                                <Input type="text" className="no-arrow no-scroll" />
                            </Form.Item>
                        ) : null}
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default ChangeAlertStatusV2;
