import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { OI_URL } from "../config/config";
import TxFindAccessToken from './TxFindAccessToken';
import DefaultPageSize from "./Table/DefaultPageSize";

const DataFetchHookOI = (initialurl, requestMethod = "POST", accessToken = null, defaultRequestData = { page: 1, size: 20, order: "DESC" }, maxRange = 3) => {
    const [data, setData] = useState({ datalist: [] });
    const [totalResult, setTotalResult] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isError, setIsError] = useState(false);
    const [url, setUrl] = useState(OI_URL + initialurl);
    const [requestData, setRequestData] = useState({ ...defaultRequestData, size: DefaultPageSize() });
    const [method, setMethod] = useState(requestMethod);
    const [token, setToken] = useState(accessToken); // eslint-disable-line
    const [maxDateRange, setMaxDateRange] = useState(maxRange); // eslint-disable-line
    const DATE_FORMAT_FROM = "YYYY-MM-DD 00:00:00";
    const DATE_FORMAT_TO = "YYYY-MM-DD 23:59:59";

    const getStartDate = () =>
        requestData && requestData.startDate
            ? moment(requestData.startDate, DATE_FORMAT_FROM)
            : moment().subtract(maxDateRange, "months").format(DATE_FORMAT_FROM);
    const getEndDate = () => (requestData && requestData.endDate ? moment(requestData.endDate, DATE_FORMAT_TO) : moment().format(DATE_FORMAT_TO));
    const getPage = () => (requestData && requestData.page ? requestData.page : 1);
    const getSize = () => (requestData && requestData.size ? requestData.size : 20);
    const getOrder = () => (requestData && requestData.order === "ASC" ? requestData.order : "DESC");

    useEffect( () => {
        
        const fetchdata = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                var newtoken = await TxFindAccessToken();
                let result;
                let options = null;
                // if (token) {
                //     options = { headers: { Authorization: "Bearer " + accessToken } };
                // }
                options = { headers: { Authorization: "Bearer " + newtoken } }; // Temporary solution until txuser ready to use
                let param = { ...requestData, dateFrom: getStartDate(), dateTo: getEndDate(), page: getPage(), size: getSize(), order: getOrder() };
                if (method === "POST") {
                    result = await axios
                        .post(url, param, options)
                        .then((result) => result)
                        .catch((error) => ({ data: false }));
                } else {
                    result = await axios
                        .get(url, options)
                        .then((result) => result)
                        .catch((error) => ({ data: false }));
                }
                if (result.data) {
                    if (result.data.list) {
                        setData({ datalist: result.data.list });
                    }
                    if (result.data.totalResult >= 0) {
                        setTotalResult(result.data.totalResult);
                    }
                    if (result.data.msg) {
                        setErrorMsg(result.data);
                        setIsError(true);
                    }
                } else {
                    setIsError(true);
                }
            } catch (err) {
                console.log("Error fetching data:",err);
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchdata();
    }, [url, requestData,method]); // eslint-disable-line react-hooks/exhaustive-deps

    return [{ data, totalResult, requestData, isLoading, isError, errorMsg }, setUrl, setRequestData, setMethod, setToken];
};
export default DataFetchHookOI;