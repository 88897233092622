import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;
const DATA_URL = "/api/v1/txshield/merchant/create/";

const CreateMerchant = (props) => {
    const { t, fetchMerchants } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [providerList, setProviderList] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if(!providerList) {
            getProviderList();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getProviderList = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/providers`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setProviderList(data);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const initData = {
        name: "",
        providerId: "",
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        form.setFieldsValue(initData);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setLoading(false);
        setIsModalVisible(false);
    };

    const formValChange = (chgV, allV) => {
        setData(allV);
    };

    const onFinish = async () => {
        const apiUrl = OI_URL + DATA_URL;

        axios
            .post(apiUrl, data, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.oi.merchant_created"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchMerchants();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const isOkButtonProps = () => !data || !data.name || loading;

    return (
        <>
            <Button type="link" onClick={showModal} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.oi.new_merchant")}
            </Button>
            <Modal
                title={t("contents.oi.create_merchant")}
                width={600}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="name" label={t("contents.oi.form.name")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="providerId" label={t("contents.oi.form.provider")} rules={[{ required: true }]}>
                        <Select placeholder={t("contents.merchants.form.please_select")}>
                            {providerList
                                ? providerList.map((item) => (
                                        <Option key={item.providerId} value={item.providerId}>
                                            {item.name}
                                        </Option>
                                    ))
                                : null}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateMerchant;