import React, { useState, useEffect, useCallback } from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import SortOrder from "../../helper/Table/SortOrder";
import CustomPaginationV3 from "../../helper/Table/CustomPaginationV3";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import { Table, PageHeader, Card, Form, Select, Row, Col, Input, Button } from "antd";
import { SearchOutlined, CheckCircleOutlined, FieldTimeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ExportModalV3 from "../../helper/ExportModalV3";
import moment from "moment";
import { API_3DS_TX } from "../../config/config";
import { API_MERCHANT } from "../../config/config";
import axios from "axios";
import GroupAccess from "./../../helper/GroupAccess";
import { Resizable } from "react-resizable";

// import { DatePicker } from "antd";
// const { RangePicker } = DatePicker;

const { Option } = Select;
const DATA_URL = API_3DS_TX + "/transaction";
const DATA_URL_EXPORT = API_3DS_TX + "/reports";
const MERCHANT_DATA_URL = API_MERCHANT + "/list";

const MAX_DATERANGE = 3;
const DEFAULT_DATERANGE = 7;
// const MAX_DATERANGE = 0;
// const MAX_RANGETYPE = "months";
const DEFAULT_RANGETYPE = "days";
// const TODAY = moment();
const START_DATE = moment().subtract(DEFAULT_DATERANGE, DEFAULT_RANGETYPE);
const END_DATE = moment();

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{
                enableUserSelectHack: false,
            }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const ThreeDSecureList = (props) => {
    // eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookV3(
        DATA_URL,
        "POST",
        {
            startDate: START_DATE,
            endDate: END_DATE,
            page: 1,
            size: 20,
            order: "DESC",
        },
        false
    );
    const [formData, setFormData] = useState();
    const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    // const [dateValue, setDateValue] = useState(TODAY); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const [latestTotal, setLatestTotal] = useState(null);
    const [merchantList, setMerchantList] = useState([]);

    const { t } = props;
    // const dateFormat = "YYYY/MM/DD";
    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        // dateValue: dateValue,
        field: null,
        keyword: null,
        status: [],
        currency: [],
        order: null,
        merchant: [],
    };
    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
        if (merchantList.length === 0) {
            getMerchantList();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantList = useCallback(() => {
        axios
            .post(
                MERCHANT_DATA_URL,
                {getall:true},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setMerchantList(data.list);
            })
            .catch(() => false);
    }, []);

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const exportHeaders = [
        { label: "Transaction Date", key: "txh.timestamp" },
        { label: "TXID", key: "txh.txid" },
        { label: "Site Name", key: "rs.name" },
        { label: "Card No.", key: "JSON_UNQUOTE(JSON_EXTRACT(hil.data, '$.dataSentIn.txnInfo.acctNumber'))" },
        { label: "Action", key: "txh.tx_action" },
        { label: "Status", key: "txh.response" },
    ];

    const exportHeadersOptional = [];

    const [columns, setColumns] = useState([
        {
            dataIndex: "timestamp",
            key: "timestamp",
            title: t("tables.fields.transaction_date"),
            render: (timestamp) => moment(timestamp).format("YYYY-MM-DD HH:mm:ss"),
            width: 150,
            sorter: {
                compare: (a, b) => moment(a.timestamp).diff(moment(b.timestamp)),
                //   multiple: 4,
            },
            sortOrder: SortOrder(sortedInfo, "timestamp"),
        },
        {
            dataIndex: "txid",
            key: "txid",
            title: t("tables.fields.txid"),
            width: 150,
            sorter: {
                compare: (a, b) => a.txid - b.txid,
                //    multiple: 1,
            },
            sortOrder: SortOrder(sortedInfo, "txid"),
            render: (id, record) => (
                <a href={`/3dsecure/${id}?tx_action=${record.tx_action}`} target="_blank" rel="noopener noreferrer">
                    {record.txid}
                </a>
            ),
        },
        {
            dataIndex: "sitename",
            key: "sitename",
            title: t("tables.fields.sitename"),
            width: 150,
            sorter: {
                compare: (a, b) => a.sitename.localeCompare(b.sitename),
                //    multiple: 6,
            },
            sortOrder: SortOrder(sortedInfo, "sitename"),
        },
        {
            dataIndex: "card_no",
            key: "card_no",
            title: t("tables.fields.card_no"),
            width: 150,
            sorter: {
                compare: (a, b) => a.card_no.localeCompare(b.card_no),
                //   multiple: 5,
            },
            sortOrder: SortOrder(sortedInfo, "card_no"),
        },
        {
            dataIndex: "tx_action",
            key: "tx_action",
            title: t("tables.fields.action"),
            width: 150,
            sorter: {
                compare: (a, b) => a.tx_action.localeCompare(b.tx_action),
                //     multiple: 2,
            },
            sortOrder: SortOrder(sortedInfo, "tx_action"),
        },
        {
            dataIndex: "response",
            key: "response",
            title: t("tables.fields.status"),
            width: 150,
            sorter: {
                compare: (a, b) => a.response.localeCompare(b.response),
                //   multiple: 3,
            },
            sortOrder: SortOrder(sortedInfo, "response"),
            render: (response, record) => getStatus(response),
        },
    ]);

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            if (formData.dateValue) {
                startDate = formData.dateValue ? formData.dateValue : null;
                endDate = formData.dateValue ? formData.dateValue : null;
            }
            searchData = {
                keyword: formData.keyword ? formData.keyword.trim() : null,
                field: formData.field,
                status: formData.status,
                currency: formData.currency,
                order: formData.order,
                mid: formData.merchant,
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const getStatus = (status) => {
        if (!status) return "";
        let statusText = status.charAt(0) + status.toLowerCase().slice(1);
        switch (status) {
            case "APPROVED":
                return (
                    <div className="d-flex align-items-center">
                        <CheckCircleOutlined style={{ color: "#52c41a", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            case "PENDING":
                return (
                    <div className="d-flex align-items-center">
                        <FieldTimeOutlined style={{ color: "#1890ff", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            case "DECLINED":
            case "PAYG_ERROR":
                return (
                    <div className="d-flex align-items-center">
                        <CloseCircleOutlined style={{ color: "#f5222d", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            default:
                return "";
        }
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        {/* <Col className="gutter-row" span={5}>
                            <Form.Item name="dateValue">
                                <DatePicker initialValues={moment(TODAY.format(dateFormat), dateFormat)} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col> */}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange">
                                <CustomDateRangeV3 t={t} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                            <Form.Item name="field">
                                <Select 
                                        showSearch
                                        showArrow="true"
                                        style={{ width: "100%" }} 
                                        placeholder={t("tables.fields.field")}>
                                    <Option value="txid">{t("tables.fields.txid")}</Option>
                                    <Option value="card_no">{t("tables.fields.card_number")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }} xl={{ span: GroupAccess("SYSADMIN") ? 7 : 9 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        {GroupAccess("SYSADMIN") ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 7 }}>
                                <Form.Item name="merchant">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        showArrow="true"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        placeholder={t("contents.merchants.title")}
                                    >
                                        {merchantList
                                            ? merchantList.map((item) => (
                                                  <Option key={item.mid} value={item.mid}>
                                                      {item.name + ` (mid: ` + item.mid + `)`}
                                                  </Option>
                                              ))
                                            : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            ""
                        )}
                        {/* <Col className="gutter-row" span={5}>

                            <Form.Item name="order">
                                <Select style={{ width: "100%" }} placeholder="Order">
                                    <Option value="ASC">ASC</Option>
                                    <Option value="DESC">DESC</Option>
                                </Select>
                        </Form.Item>
                        </Col>*/}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="currency">
                                <Select 
                                    showArrow="true"
                                    mode="multiple" 
                                    maxTagCount="responsive" 
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.currency")}>
                                    <Option value="AUD">AUD</Option>
                                    <Option value="EUR">EUR</Option>
                                    <Option value="USD">USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 4 }}>
                            <Form.Item name="status">
                                <Select 
                                    showArrow="true"
                                    mode="multiple" 
                                    maxTagCount="responsive" 
                                    allowClear 
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.status")}>
                                    <Option value="APPROVED">APPROVED</Option>
                                    <Option value="DECLINED">DECLINED</Option>
                                    <Option value="PAYG_ERROR">PAYG_ERROR</Option>
                                    <Option value="PENDING">PENDING</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 12 }}
                            lg={{ span: 4, offset: 16 }}
                            xl={{ span: 3, offset: GroupAccess("SYSADMIN") ? 7 : 2 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.3dsecure.title")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`3ds_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const handleResize =
        (index) =>
        (_, { size }) => {
            const newColumns = [...columns];
            newColumns[index] = { ...newColumns[index], width: size.width };
            setColumns(newColumns);
        };

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index),
        }),
    }));

    return (
        <div>
            <PageHeader title={t("contents.3dsecure.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                loading={isLoading}
                title={() => getHeaderTable()}
                components={{
                    header: {
                        cell: ResizableTitle,
                    },
                }}
                rowKey="txid"
                size="small"
                columns={mergeColumns}
                className="tableOverflow table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend", "ascend"]}
                pagination={CustomPaginationV3(
                    { totalResult, currentPage, requestData, setRequestData, setCurrentPage, latestTotal, setLatestTotal },
                    isUpdated
                )}
            />
        </div>
    );
};

export default ThreeDSecureList;
