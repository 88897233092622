import React, { useState, useEffect } from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import { PageHeader, Card, Form, Select, Row, Col, Button } from "antd";
import { API_3DS_TX } from "../../config/config";
import { API_SITE } from "../../config/config";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import BreakdownByStatus from "./StatsDetails/BreakdownByStatus";
import BreakdownByReason from "./StatsDetails/BreakdownByReason";
import BreakdownByStatusAndReason from "./StatsDetails/BreakdownByStatusAndReason";
import Swal from "sweetalert2";

const { Option } = Select;
const SIDS_URL = API_SITE + "/list";
const STATS_URL = API_3DS_TX + "/stats";
const MAX_DATERANGE = 1;
const DEFAULT_DATERANGE = 7;
const DEFAULT_RANGETYPE = "days";
const START_DATE = moment().subtract(DEFAULT_DATERANGE, DEFAULT_RANGETYPE);
const END_DATE = moment();
const DATE_FORMAT = "YYYY-MM-DD";

const ThreeDSecureStats = (props) => {
    const [{ data }] = DataFetchHookV3(SIDS_URL);
    const [selectedSID, setSelectedSID] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [statsData, setStatsData] = useState(null);
    const [detailsBoxTitle, setDetailsBoxTitle] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [formData, setFormData] = useState();
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const { t } = props;

    const onTypeSelectorChange = async (value) => {
        await setSelectedType(value);
        setDetailsBoxTitle(value);
    };

    const onSIDSelectorChange = async (value) => {
        await setSelectedSID(value);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
        setStatsData(null);
    };

    const onFinish = () => {
        setFetching(true);

        let startDate = null;
        let endDate = null;
        let postData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }

            if (formData.dateValue) {
                startDate = formData.dateValue ? formData.dateValue : null;
                endDate = formData.dateValue ? formData.dateValue : null;
            }

            if(!startDate && endDate) {
                startDate = moment().subtract(31, "days");
            } else if(startDate && !endDate) {
                endDate = moment().add(31, "days");
            } else if(!startDate && !endDate) {
                startDate = moment().subtract(31, "days");
                endDate = moment();
            }

            postData = {
                dateFrom: startDate.format(DATE_FORMAT),
                dateTo: endDate.format(DATE_FORMAT),
                sid: formData.sid,
                type: formData.type
            };

            axios
                .post(STATS_URL, postData, {
                    headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") },
                })
                .then(async ({ data }) => {
                    setStatsData(data);
                    setFetching(false);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        showConfirmButton: true
                    });
                });
        }
    };

    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        type: null,
        sid: null,
    };

    const [form] = Form.useForm();

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="type" rules={[{ required: true, message: t("required_field") }]}>
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t("contents._3dsecure_stats.please_select_report_type")}
                                    value={selectedType}
                                    onChange={onTypeSelectorChange}
                                >
                                    <Option value="status">{t(`contents._3dsecure_stats.short_status`)}</Option>
                                    <Option value="reason">{t(`contents._3dsecure_stats.short_reason`)}</Option>
                                    <Option value="status_and_reason">{t(`contents._3dsecure_stats.short_status_and_reason`)}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="sid" rules={[{ required: true, message: t("required_field") }]}>
                                <Select
                                    value={selectedSID}
                                    onChange={onSIDSelectorChange}
                                    disabled={fetching}
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t("contents._3dsecure_stats.please_select_sid")}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {data.datalist.map((item) => (
                                        <Option key={item.sid} value={item.sid}>
                                            {item.name + ` (sid: ` + item.sid + `)`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange" rules={[{ required: true, message: t("required_field") }]}>
                                <CustomDateRangeV3 t={t} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8, offset: 16 }} lg={{ span: 6, offset: 0 }} xl={{ span: 4, offset: 2 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block loading={fetching} disabled={fetching}>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents._3dsecure_stats.title")}></PageHeader>
            {AdvancedSearchForm()}
            {statsData && !fetching ? (
                <>
                    {formData.type === "status" && statsData && !fetching ? (
                        <BreakdownByStatus t={t} statsData={statsData} detailsBoxTitle={detailsBoxTitle} />
                    ) : null}
                    {formData.type === "reason" && statsData && !fetching ? (
                        <BreakdownByReason t={t} statsData={statsData} detailsBoxTitle={detailsBoxTitle} />
                    ) : null}
                    {formData.type === "status_and_reason" && statsData && !fetching ? (
                        <BreakdownByStatusAndReason t={t} statsData={statsData} detailsBoxTitle={detailsBoxTitle} />
                    ) : null}
                </>
            ) : null}
        </div>
    );
};
export default ThreeDSecureStats;
