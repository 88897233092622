import React, { useState } from "react";
import { PageHeader, Table, Tabs, Card, Form, Row, Col } from "antd";
import { Empty } from "antd";
import FormatNumber from "../../helper/FormatNumber";
import { OI_URL } from "../../config/config";
import axios from "axios";
import moment from "moment";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { TabPane } = Tabs;

const CartDetail = (props) => {
    const [data, setData] = useState(null);
    const [lookup, setLookup] = useState(null);
    const [attachment, setAttachment] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;

    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
    };

    const getAlert = async (id) => {
        const apiUrl = `/api/v1/txshield/cartDetails/${id}`;
        axios
            .post(OI_URL + apiUrl, {}, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => error);
    };

    const getCartAttachmentData = (id) => {
        const apiUrl = `/api/v1/cart/${id}/attachment/`;

        axios
            .get(OI_URL + apiUrl)
            .then(({ data }) => {
                setAttachment(data);
            })
            .catch((error) => error);
    };

    const getLookup = async (ref) => {
        const apiUrl = `/api/v1/txshield/lookupHistory/`;

        axios
            .post(OI_URL + apiUrl, {
                value: [ref],
                field: ["txShieldOICartReference"],
                operator: ["="],
            }, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setLookup(data.list);
            })
            .catch((error) => error);
    };

    if (typeof props.match.params.id != "undefined" && loading && !data) {
        getAlert(props.match.params.id);
        getLookup(props.match.params.id);
        getCartAttachmentData(props.match.params.id);
    }

    const getPlainText = (text) => text;

    const getLink = (link) => (
        <a href={link} target="_blank" rel="noopener noreferrer" className="ant-form-text">
            {link}
        </a>
    );

    const getFormatDate = (date) => <span className="ant-form-text">{Date.parse(date) ? moment(date).format("YYYY-MM-DD HH:mm:ss") : ""}</span>;

    const getCartDetailsLeftForm = () => {
        let fields = [
            {
                label: t("contents.oi.form.txshieldoicartreference"),
                name: "txShieldOICartReference",
                value: getPlainText(data.txShieldOICartReference ? data.txShieldOICartReference : ""),
            },
            {
                label: t("contents.oi.form.arn"),
                name: "arn",
                value: getPlainText(data.arn ? data.arn : ""),
            },
            {
                label: t("contents.oi.form.transactiondate"),
                name: "transactionDate",
                value: getFormatDate(data.transactionDate),
            },
            {
                label: t("contents.oi.form.authorizationcode"),
                name: "authorizationCode",
                value: getPlainText(data.authorizationCode ? data.authorizationCode : ""),
            },
            {
                label: t("contents.oi.form.authorizationdate"),
                name: "authorizationDate",
                value: getFormatDate(data.authorizationDate),
            },
            {
                label: t("contents.oi.form.settlementdate"),
                name: "settlementDate",
                value: moment(data.settlementDate).isValid() ? getFormatDate(data.settlementDate) : "-",
            },
        ];

        // if (attachment && data.details.attachments[0].data) {
        //     fields = [
        //         ...fields,
        //         {
        //             label: t("contents.oi.form.attachmentsid"),
        //             name: "attachmentsId",
        //             value: getPlainText(data.details.attachments[0].id),
        //         },
        //         {
        //             label: t("contents.oi.form.attachmentsfile"),
        //             name: "attachmentsFile",
        //             value: attachment ? (
        //                 <a
        //                     download={data.details.attachments[0].friendlyFileName}
        //                     href={`data:application/pdf;base64,${attachment}`}
        //                     target="_blank"
        //                     rel="noopener noreferrer"
        //                 >
        //                     {t("tables.actions.download")}
        //                 </a>
        //             ) : null,
        //         },
        //     ];
        // }

        fields = [
            ...fields,
            {
                label: t("contents.oi.form.alerthappened"),
                name: "alertHappened",
                value: getPlainText(data.alertHappened ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.chargebackhappened"),
                name: "chargebackHappened",
                value: getPlainText(data.chargebackHappened ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.datecreated"),
                name: "dateCreated",
                value: getFormatDate(data.dateCreated),
            },
        ];

        return showCardTwoBlockInfo(fields);
    };

    const getCartDetailsRightForm = () => {
        let fields = [
            {
                label: t("contents.oi.form.merchantid"),
                name: "merchantId",
                value: getPlainText(data.merchantId ? data.merchantId : ""),
            },
            {
                label: t("contents.oi.form.merchantref"),
                name: "merchantRef",
                value: getPlainText(data.merchantRef ? data.merchantRef : ""),
            },
            {
                label: t("contents.oi.form.paymentdescriptor"),
                name: "paymentDescriptor",
                value: getPlainText(data.paymentDescriptor ? data.paymentDescriptor : ""),
            },
            {
                label: t("contents.oi.form.authorizationamount"),
                name: "authorizationAmount",
                value: getPlainText(`${FormatNumber(data.authorizationAmount)} ${data.authorizationCurrency}`),
            },
            {
                label: t("contents.oi.form.settlementamount"),
                name: "settlementAmount",
                value: data.settlementAmount > 0 ? getPlainText(`${FormatNumber(data.settlementAmount)} ${data.settlementCurrency}`) : "-",
            },
            {
                label: t("contents.oi.form.cardno"),
                name: "cardno",
                value: getPlainText(`${data.creditCardBin}******${data.creditCardLastFour}`),
            },
            {
                label: t("contents.oi.form.alertreference"),
                name: "alertReference",
                value: getPlainText(data.alertReference ? data.alertReference : ""),
            },
            {
                label: t("contents.oi.form.chargebackreference"),
                name: "chargebackReference",
                value: getPlainText(data.chargebackReference ? data.chargebackReference : ""),
            },
        ];

        return showCardTwoBlockInfo(fields);
    };

    const getTransactionDetailsForm = () => {
        let tx = data.details.transactionDetail ? data.details.transactionDetail : null;
        if (!tx) {
            return showCardEmpty();
        }
        let billingAddress =
            tx && tx.billingAddressDetails ? (Array.isArray(tx.billingAddressDetails) ? tx.billingAddressDetails[0] : tx.billingAddressDetails) : null;
        let shippingAddress = tx && tx.shippingAddressDetails && tx.shippingAddressDetails[0] ? tx.shippingAddressDetails[0] : null;
        let fields = [
            {
                label: t("contents.oi.form.taxamount"),
                name: "taxAmount",
                value: getPlainText(`${FormatNumber(tx.taxAmount)} ${tx.taxAmountCurrency}`),
            },
            {
                label: t("contents.oi.form.billingaddressdetails"),
                name: "billingAddressDetails",
                value: getPlainText(
                    billingAddress
                        ? `${billingAddress.address[0]} ${billingAddress.city} ${billingAddress.state} ${billingAddress.zip} ${billingAddress.country}`
                        : null
                ),
            },
            {
                label: t("contents.oi.form.shippingandhandlingamount"),
                name: "shippingAndHandlingAmount",
                value: getPlainText(`${FormatNumber(tx.shippingAndHandlingAmount)} ${tx.shippingAndHandlingCurrency}`),
            },
            {
                label: t("contents.oi.form.shippingaddressdetails"),
                name: "shippingAddressDetails",
                value: getPlainText(
                    shippingAddress
                        ? `${shippingAddress.address} ${shippingAddress.city} ${shippingAddress.state} ${shippingAddress.zip} ${shippingAddress.country}`
                        : ""
                ),
            },
            {
                label: t("contents.oi.form.totalamount"),
                name: "totalAmount",
                value: getPlainText(`${FormatNumber(tx.totalAmount)} ${tx.totalAmountCurrency}`),
            },
            {
                label: t("contents.oi.form.dateofrefund"),
                name: "dateOfRefund",
                value: getFormatDate(tx.dateOfRefund),
            },
            {
                label: t("contents.oi.form.refundamount"),
                name: "refundAmount",
                value: getPlainText(`${FormatNumber(tx.refundAmount)} ${tx.refundCurrency}`),
            },
            {
                label: t("contents.oi.form.refundprocessed"),
                name: "refundProcessed",
                value: getPlainText(tx.refundProcessed ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.avsresultcode"),
                name: "avsResultCode",
                value: getPlainText(tx.avsResultCode),
            },
            {
                label: t("contents.oi.form.avschecked"),
                name: "avsChecked",
                value: getPlainText(tx.avsChecked ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.cvvresultcode"),
                name: "cvvResultCode",
                value: getPlainText(tx.cvvResultCode),
            },
            {
                label: t("contents.oi.form.cvvchecked"),
                name: "cvvChecked",
                value: getPlainText(tx.cvvChecked ? t("contents.alerts.yes") : t("contents.alerts.no")),
            },
            {
                label: t("contents.oi.form.threedsecicode"),
                name: "threeDSEciCode",
                value: getPlainText(tx.threeDSEciCode),
            },
            {
                label: t("contents.oi.form.threedsauthenticationvalue"),
                name: "threeDSAuthenticationValue",
                value: getPlainText(tx.threeDSAuthenticationValue),
            },
            {
                label: t("contents.oi.form.devicename"),
                name: "deviceName",
                value: getPlainText(tx.deviceName),
            },
            {
                label: t("contents.oi.form.deviceid"),
                name: "deviceId",
                value: getPlainText(tx.deviceId),
            },
            {
                label: t("contents.oi.form.devicelocation"),
                name: "deviceLocation",
                value: getPlainText(tx.deviceLocation),
            },
            {
                label: t("contents.oi.form.ipaddress"),
                name: "ipAddress",
                value: getPlainText(tx.ipAddress),
            },
            {
                label: t("contents.oi.form.refundpolicy"),
                name: "refundPolicy",
                value: getLink(tx.refundPolicy),
            },
            {
                label: t("contents.oi.form.termsandconditions"),
                name: "termsAndConditions",
                value: getLink(tx.refundPolicy),
            },
        ];
        return showCardInfo(fields);
    };

    const getCustomerInformationForm = () => {
        if (!data.details.customerInformation) {
            return showCardEmpty();
        }
        const cust = data.details.customerInformation;
        const emailAddress = data.details.customerInformation.emailAddress
            ? data.details.customerInformation.emailAddress.length > 0
                ? data.details.customerInformation.emailAddress[0].emailAddress
                : null
            : null;
        const phoneNumber = data.details.customerInformation.phoneNumber
            ? data.details.customerInformation.phoneNumber.length > 0
                ? data.details.customerInformation.phoneNumber[0].phoneNumber
                : null
            : null;
        let fields = [
            {
                label: t("contents.oi.form.customername"),
                name: "name",
                value: getPlainText(`${cust.lastName} ${cust.lastName}`),
            },
            {
                label: t("contents.oi.form.firsttransactiondate"),
                name: "firstTransactionDate",
                value: getFormatDate(cust.firstTransactionDate),
            },
            {
                label: t("contents.oi.form.emailaddress"),
                name: "emailAddress",
                value: getPlainText(emailAddress),
            },
            {
                label: t("contents.oi.form.lengthofrelationship"),
                name: "lengthOfRelationship",
                value: getPlainText(cust.lengthOfRelationship),
            },
            {
                label: t("contents.oi.form.phonenumber"),
                name: "phoneNumber",
                value: getPlainText(phoneNumber),
            },
        ];
        return showCardInfo(fields);
    };

    const getPastTransactionsTable = () => {
        let dataSource = [];
        if (data.details && data.details.pastTransactions) {
            dataSource = data.details.pastTransactions;
        }

        const columns = [
            {
                dataIndex: "dateOfPurchase",
                key: "dateOfPurchase",
                title: t("tables.fields.dateofpurchase"),
                render: (dateOfPurchase, record) => getFormatDate(dateOfPurchase),
            },
            {
                dataIndex: "amountOfPurchase",
                key: "amountOfPurchase",
                title: t("tables.fields.amountofpurchase"),
                align: "left",
                render: (amountOfPurchase, record) => FormatNumber(amountOfPurchase),
            },
            {
                dataIndex: "creditCardNumber",
                key: "creditCardNumber",
                title: t("tables.fields.creditcardnumber"),
                render: (creditCardNumber, record) => `${record.creditCardBin}******${record.creditCardFour}`,
            },
            {
                dataIndex: "recurringTransaction",
                key: "recurringTransaction",
                title: t("tables.fields.recurringtransaction"),
            },
        ];

        return <Table columns={columns} rowKey="dateOfPurchase" pagination={false} dataSource={dataSource} />;
    };

    const getProductPurchasedTable = () => {
        let dataSource = [];
        if (data.details && data.details.productPurchased) {
            dataSource = data.details.productPurchased;
        }
        const columns = [
            {
                dataIndex: "productName",
                key: "productName",
                title: t("tables.fields.productname"),
            },
            {
                dataIndex: "productDescription",
                key: "productDescription",
                title: t("tables.fields.productdescription"),
            },
            {
                dataIndex: "productUrl",
                key: "productUrl",
                title: t("tables.fields.producturl"),
                render: (productUrl, record) => <a href={productUrl}>{productUrl}</a>,
            },
            {
                dataIndex: "unitPriceAmount",
                key: "unitPriceAmount",
                title: t("tables.fields.unitpriceamount"),
                align: "left",
                width: 150,
                render: (unitPriceAmount, record) => `${FormatNumber(unitPriceAmount)} ${record.unitPriceCurrency}`,
            },
            {
                dataIndex: "quantity",
                key: "quantity",
                title: t("tables.fields.quantity"),
                align: "left",
                width: 80,
            },
        ];

        return <Table columns={columns} rowKey="productName" pagination={false} dataSource={dataSource} />;
    };

    const getIndustryDetailsFlightDetailsTable = () => {
        let dataSource = [];
        if (
            data.details &&
            data.details.productPurchased &&
            data.details.productPurchased[0] &&
            data.details.productPurchased[0].industryDetails &&
            data.details.productPurchased[0].industryDetails.flightDetails
        ) {
            dataSource = data.details.productPurchased[0].industryDetails.flightDetails;
        }

        const columns = [
            {
                dataIndex: "memberRewardsNumber",
                key: "memberRewardsNumber",
                title: t("tables.fields.memberrewardsnumber"),
            },
            {
                dataIndex: "manifest",
                key: "manifest",
                title: t("tables.fields.manifest"),
            },
        ];

        return <Table columns={columns} rowKey="memberRewardsNumber" pagination={false} dataSource={dataSource} />;
    };

    const getIndustryDetailsHotelDetailsTable = () => {
        let dataSource = [];
        if (
            data.details.productPurchased &&
            data.details.productPurchased[0] &&
            data.details.productPurchased[0].industryDetails &&
            data.details.productPurchased[0].industryDetails.hotelDetails
        ) {
            dataSource = data.details.productPurchased[0].industryDetails.hotelDetails;
        }

        const columns = [
            {
                dataIndex: "reservationNumber",
                key: "reservationNumber",
                title: t("tables.fields.reservationnumber"),
            },
            {
                dataIndex: "reservationDate",
                key: "reservationDate",
                title: t("tables.fields.reservationdate"),
                render: (Reservation, record) => getFormatDate(Reservation),
            },
            {
                dataIndex: "checkInDate",
                key: "checkInDate",
                title: t("tables.fields.checkindate"),
                render: (checkInDate, record) => getFormatDate(checkInDate),
            },
            {
                dataIndex: "checkOutDate",
                key: "checkOutDate",
                title: t("tables.fields.checkoutdate"),
                render: (checkOutDate, record) => getFormatDate(checkOutDate),
            },
            {
                dataIndex: "memberRewardsNumber",
                key: "memberRewardsNumber",
                title: t("tables.fields.memberrewardsnumber"),
            },
        ];

        return <Table columns={columns} rowKey="reservationNumber" pagination={false} dataSource={dataSource} />;
    };

    const getPhysicalFulfillmentTable = () => {
        let dataSource = [];
        if (data.details && data.details.deliveryDetails && data.details.deliveryDetails.physicalFulfillment) {
            dataSource = data.details.deliveryDetails.physicalFulfillment;
        }
        const columns = [
            {
                dataIndex: "shippingCarrierUrl",
                key: "shippingCarrierUrl",
                title: t("tables.fields.shippingcarrierurl"),
                render: (shippingCarrierUrl, record) => <a href={shippingCarrierUrl}>{shippingCarrierUrl}</a>,
            },
            {
                dataIndex: "trackingNumber",
                key: "trackingNumber",
                title: t("tables.fields.trackingnumber"),
            },
            {
                dataIndex: "dateOfShipment",
                key: "dateOfShipment",
                title: t("tables.fields.dateofshipment"),
                render: (dateOfShipment, record) => getFormatDate(dateOfShipment),
            },
            {
                dataIndex: "dateOfDelivery",
                key: "dateOfDelivery",
                title: t("tables.fields.dateofdelivery"),
                render: (dateOfDelivery, record) => getFormatDate(dateOfDelivery),
            },
            {
                dataIndex: "signatureConfirmation",
                key: "signatureConfirmation",
                title: t("tables.fields.signatureconfirmation"),
            },
        ];

        return <Table columns={columns} rowKey="shippingCarrierUrl" pagination={false} dataSource={dataSource} />;
    };

    const getDigitalServiceDeliveryTable = () => {
        let dataSource = [];
        if (data.details && data.details.deliveryDetails && data.details.deliveryDetails.digitalServiceDeliver) {
            dataSource = data.details.deliveryDetails.digitalServiceDeliver;
        }

        const columns = [
            {
                dataIndex: "proofOfDelivery",
                key: "proofOfDelivery",
                title: t("tables.fields.proofofdelivery"),
            },
            {
                dataIndex: "proofOfUsage",
                key: "proofOfUsage",
                title: t("tables.fields.proofofusage"),
            },
            {
                dataIndex: "locationOfUsage",
                key: "locationOfUsage",
                title: t("tables.fields.locationofusage"),
            },
            {
                dataIndex: "frequencyOfUsage",
                key: "frequencyOfUsage",
                title: t("tables.fields.frequencyofusage"),
            },
            {
                dataIndex: "notes",
                key: "notes",
                title: t("tables.fields.notes"),
            },
        ];

        return <Table columns={columns} rowKey="proofOfDelivery" pagination={false} dataSource={dataSource} />;
    };

    const getCrmDetailsTable = () => {
        let dataSource = [];
        if (data.details && data.details.crmDetails) {
            dataSource = data.details.crmDetails;
        }

        const columns = [
            {
                dataIndex: "dateOfInteraction",
                key: "dateOfInteraction",
                title: t("tables.fields.dateofinteraction"),
                render: (dateOfInteraction, record) => getFormatDate(dateOfInteraction),
            },
            {
                dataIndex: "contactName",
                key: "contactName",
                title: t("tables.fields.contactname"),
            },
            {
                dataIndex: "notes",
                key: "notes",
                title: t("tables.fields.notes"),
            },
        ];

        return <Table columns={columns} rowKey="dateOfInteraction" pagination={false} dataSource={dataSource} />;
    };

    const getLookupHistoryTable = () => {
        const columns = [
            {
                dataIndex: "_id",
                key: "_id",
                title: t("tables.fields.id"),
            },
            {
                dataIndex: "lookupReference",
                key: "lookupReference",
                title: t("tables.fields.lookupreference"),
            },
            {
                dataIndex: "lookupDate",
                key: "lookupDate",
                title: t("tables.fields.lookupdate"),
                render: (lookupDate, record) => getFormatDate(lookupDate),
            },
        ];

        return <Table columns={columns} rowKey="_id" pagination={false} dataSource={lookup} />;
    };

    const getAttachments = () => {
        const columns = [
            {
                dataIndex: "id",
                key: "id",
                width: 200,
                title: t("contents.oi.form.attachmentsid"),
            },
            {
                dataIndex: "friendlyFileName",
                key: "friendlyFileName",
                title: t("contents.oi.form.attachmentsfile"),
            },
            {
                dataIndex: "data",
                key: "data",
                width: 150,
                title: t("tables.fields.action"),
                render: (data, record) => (
                    <a download={data} href={`data:${record.mimeType};base64,${data}`} target="_blank" rel="noopener noreferrer">
                        {t("tables.actions.download")}
                    </a>
                ),
            },
        ];

        return <Table columns={columns} rowKey="id" pagination={false} dataSource={attachment ? attachment : []} />;
    };

    const showCardEmpty = () => (
        <Card style={{ marginBottom: "1rem" }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
    );

    const showCardInfo = (fields) => (
        <Card style={{ marginBottom: "1rem" }}>
            <Form size="small" {...formItemLayout}>
                <Row gutter={24}>
                    {fields.map((field) => (
                        <Col key={field.name} xs={24} md={12} offset={field.offset} style={{ marginBottom: 12 }}>
                            <span style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", marginRight: 6 }}>{field.label}:</span> {field.value}
                        </Col>
                    ))}
                </Row>
            </Form>
        </Card>
    );

    const showCardTwoBlockInfo = (fields) => (
        <Form size="small" {...formItemLayout}>
            {fields.map((field) => (
                <Row key={field.name}>
                    <Col key={field.name} span={24} style={{ marginBottom: 12 }}>
                        <span style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "500", marginRight: 6 }}>{field.label}:</span> {field.value}
                    </Col>
                </Row>
            ))}
        </Form>
    );

    const getMoreDetails = () => (
        <Tabs type="card">
            <TabPane tab={t("contents.oi.tabs.transaction_details")} key="transactionDetail">
                {getTransactionDetailsForm()}
            </TabPane>
            <TabPane tab={t("contents.oi.tabs.customer_information")} key="customerInformation">
                {getCustomerInformationForm()}
            </TabPane>
            <TabPane tab={t("contents.oi.tabs.past_transactions")} key="pastTransactions">
                {getPastTransactionsTable()}
            </TabPane>
            <TabPane tab={t("contents.oi.tabs.product_purchased")} key="productPurchased">
                <div style={{ marginBottom: "1.5rem" }}>{getProductPurchasedTable()}</div>
                <h3 style={{ marginBottom: "1rem" }}>{t("contents.oi.tabs.industry_details_flight_details")}</h3>
                <div style={{ marginBottom: "1.5rem" }}>{getIndustryDetailsFlightDetailsTable()}</div>
                <h3 style={{ marginBottom: "1rem" }}>{t("contents.oi.tabs.industry_details_hotel_details")}</h3>
                <div style={{ marginBottom: "1rem" }}>{getIndustryDetailsHotelDetailsTable()}</div>
            </TabPane>
            <TabPane tab={t("contents.oi.tabs.delivery_details")} key="deliveryDetails">
                <h3 style={{ marginBottom: "1rem" }}>{t("contents.oi.tabs.physical_fulfillment")}</h3>
                <div style={{ marginBottom: "1.5rem" }}>{getPhysicalFulfillmentTable()}</div>
                <h3 style={{ marginBottom: "1rem" }}>{t("contents.oi.tabs.digital_service_delivery")}</h3>
                <div style={{ marginBottom: "1rem" }}>{getDigitalServiceDeliveryTable()}</div>
            </TabPane>
            <TabPane tab={t("contents.oi.tabs.crm_details")} key="crmDetails">
                {getCrmDetailsTable()}
            </TabPane>
            <TabPane tab={t("contents.oi.tabs.lookup_history")} key="lookup">
                {getLookupHistoryTable()}
            </TabPane>
            <TabPane tab={t("contents.oi.tabs.attachments")} key="attachments">
                {getAttachments()}
            </TabPane>
        </Tabs>
    );

    return (
        <div>
            {!loading && data ? (
                <>
                    <PageHeader className="site-page-header" title={t("contents.oi.cart_details") + " (" + data.txShieldOICartReference + ")"}></PageHeader>
                    <Row gutter={[16, 16]} style={{ marginBottom: "1rem" }}>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Card style={{ minHeight: 430 }}>{getCartDetailsLeftForm()}</Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Card style={{ minHeight: 430 }}>{getCartDetailsRightForm()}</Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="tab-wrap">
                        <Col span={24}>{getMoreDetails()}</Col>
                    </Row>
                </>
            ) : null}
        </div>
    );
};

export default CartDetail;