import React, { useState, useEffect, useCallback } from "react";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import PermissionsRequired from "../../helper/PermissionsRequired";
import { Table, PageHeader, Button, Space, Checkbox, Card, Form, Select, Row, Col, Input } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import moment from "moment";
import AssociationsChecker from "./../../helper/AssociationsChecker";
import axios from "axios";
import { OI_URL } from "../../config/config";
import ExportModalV3 from "../../helper/ExportModalV3";
//import FormatAmount from "../../helper/FormatAmount";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import SortOrder from "../../helper/Table/SortOrder";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;
const DATA_URL = "/api/v1/txshield/cartlist/";
const DATA_URL_EXPORT = "/api/v1/txshield/cartlist/reports";
const MERCHANT_DATA_URL = "/api/v1/txshield/merchants/";

const MAX_DATERANGE = 3;
const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const END_DATE = moment();

const CartList = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData }, doFetch, setRequestData] = DataFetchHookOI(DATA_URL); // eslint-disable-line
    const [formData, setFormData] = useState({ hideColumn: [] });
    const [form] = Form.useForm();
    const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [currentPage, setCurrentPage] = useState(1);
    const [merchantList, setMerchantList] = useState([]);
    const oiAssocication = localStorage.getItem("oiAssocication") ? localStorage.getItem("oiAssocication").split(",") : [];
    const { t } = props;
    const initFormData = {
        keyword: null,
        field: null,
        order: null,
        associatedMerchantIds: [],
        dateRange: [START_DATE, END_DATE],
        lookup: false,
        alert: false,
        chargeback: false,
        hideColumn: [],
    };

    useEffect(() => {
        formValInit();
        getMerchantList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantList = useCallback(async () => {
        axios
            .post(OI_URL + MERCHANT_DATA_URL, { associatedMerchantIds: oiAssocication }, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(async ({ data }) => {
                await setMerchantList(data.list);
            })
            .catch(() => false);
    }, [oiAssocication]);

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const columns = [
        {
            dataIndex: "txShieldOICartReference",
            key: "txShieldOICartReference",
            title: t("tables.fields.txshieldoicartreference"),
            width: 330,
            sorter: {
                compare: (a, b) => a.txShieldOICartReference.localeCompare(b.txShieldOICartReference),
            },
            sortOrder: SortOrder(sortedInfo, "txShieldOICartReference"),
            render: (value) => {
                return (
                    <a className="oi-cart-ref-hyperlink" href={`/txfind_cart/${value}`} target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                );
            },
        },
        {
            dataIndex: "transactionDate",
            key: "transactionDate",
            title: t("tables.fields.transactiondate"),
            width: 220,
            sorter: {
                compare: (a, b) => moment(a.transactionDate).diff(moment(b.transactionDate)),
            },
            sortOrder: SortOrder(sortedInfo, "transactionDate"),
            render: (transactionDate) => moment(transactionDate).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            dataIndex: "cardno",
            key: "cardno",
            title: t("tables.fields.cardno"),
            width: 180,
            sorter: {
                compare: (a, b) => a.cardno.localeCompare(b.cardno),
            },
            sortOrder: SortOrder(sortedInfo, "cardno"),
        },
        {
            dataIndex: "merchantName",
            key: "merchantName",
            title: t("tables.fields.merchantname"),
            width: 180,
            sorter: {
                compare: (a, b) => a.merchantName - b.merchantName,
            },
            sortOrder: SortOrder(sortedInfo, "merchantName"),
        },
        {
            dataIndex: "merchantRef",
            key: "merchantRef",
            title: t("tables.fields.merchantref"),
            width: 180,
            sorter: {
                compare: (a, b) => a.merchantRef.localeCompare(b.merchantRef),
            },
            sortOrder: SortOrder(sortedInfo, "merchantRef"),
        },
        {
            dataIndex: "amount",
            key: "amount",
            title: t("tables.fields.amount"),
            width: 240,
            sorter: {
                compare: (a, b) => a.amount - b.amount,
            },
            sortOrder: SortOrder(sortedInfo, "amount"),
        },
        {
            dataIndex: "currency",
            key: "currency",
            title: t("tables.fields.currency"),
            width: 160,
            sorter: {
                compare: (a, b) => a.authCode.localeCompare(b.currency),
            },
            sortOrder: SortOrder(sortedInfo, "currency"),
        },
        {
            dataIndex: "paymentDescriptor",
            key: "paymentDescriptor",
            title: t("tables.fields.paymentdescriptor"),
            width: 180,
            sorter: {
                compare: (a, b) => a.paymentDescriptor.localeCompare(b.paymentDescriptor),
            },
            sortOrder: SortOrder(sortedInfo, "paymentDescriptor"),
        },
        {
            dataIndex: "dateCreated",
            key: "dateCreated",
            title: t("tables.fields.datecreated"),
            width: 180,
            sorter: {
                compare: (a, b) => moment(a.dateCreated).diff(moment(b.dateCreated)),
            },
            sortOrder: SortOrder(sortedInfo, "dateCreated"),
            render: (dateCreated) => moment(dateCreated).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: formData.hideColumn ? (formData.hideColumn.length < 8 ? "right" : null) : null,
            width: 80,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" href={`/txfind_cart/${record.txShieldOICartReference}`} target="_blank">
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        },
    ];

    const getColumnSource = () => {
        let initColumns = columns;
        if (formData.hideColumn) {
            return formData.hideColumn.length > 0 ? initColumns.filter((x) => !formData.hideColumn.includes(x.dataIndex)) : initColumns;
        } else {
            return initColumns;
        }
    };

    const getTableWidth = () => {
        return getColumnSource().reduce(function (a, k) {
            return a + k.width;
        }, 0);
    };

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }

            searchData = {
                value: [formData.keyword ? formData.keyword.trim() : null],
                field: [formData.field],
                operator: [formData.field === "txShieldOICartReference" ? "=" : "LIKE"],
                order: formData.order,
                lookup: formData.lookup,
                alert: formData.alert,
                chargeback: formData.chargeback,
                associatedMerchantIds: formData.associatedMerchantIds,
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 0]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange">
                                <CustomDateRangeV3 t={t} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        {merchantList ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item name="associatedMerchantIds">
                                    <Select
                                        showSearch
                                        showArrow="true"
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        placeholder={t("contents.oi.advanced_search.merchant")}
                                    >
                                        {merchantList.map((item) => (
                                            <Option key={item.merchantId} value={item.merchantId}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.field")}>
                                    <Option value="txShieldOICartReference">{t("contents.oi.advanced_search.txshieldoicartreference_field")}</Option>
                                    <Option value="arn">{t("contents.oi.advanced_search.arn_field")}</Option>
                                    <Option value="cardnumber">{t("contents.oi.advanced_search.cardnumber_field")}</Option>
                                    <Option value="paymentDescriptor">{t("contents.oi.advanced_search.descriptor_field")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 7 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]} justify="end">
                        <Col className="gutter-row" flex={"none"}>
                            <Form.Item name="lookup" valuePropName="checked" style={{ paddingTop: "8px" }}>
                                <Checkbox>{t("contents.oi.advanced_search.had_lookup")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" flex={"none"}>
                            <Form.Item name="alert" valuePropName="checked" style={{ paddingTop: "8px" }}>
                                <Checkbox>{t("contents.oi.advanced_search.alert")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" flex={1}>
                            <Form.Item name="chargeback" valuePropName="checked" style={{ paddingTop: "8px" }}>
                                <Checkbox>{t("contents.oi.advanced_search.chargeback")}</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            style={{ marginBottom: "16px" }}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 12 }}
                            xl={{ span: 5 }}
                        >
                            <Form.Item name="hideColumn">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("tables.actions.hide_column")}
                                >
                                    {columns.map((item, index) => {
                                        return item.dataIndex !== "action" ? (
                                            <Option key={index} value={item.dataIndex}>
                                                {item.title}
                                            </Option>
                                        ) : null;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            style={{ marginBottom: "8px" }}
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 3 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) =>
        datalist && Array.isArray(datalist)
            ? datalist.map((data) => ({
                  ...data,
                  cardno: `${data.creditCardBin}******${data.creditCardLastFour}`,
                  arn: data.arn ? data.arn : "",
                  settlementAmount: data.settlementAmount > 0 ? data.settlementAmount : "-",
              }))
            : [];

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "TxFind Cart Reference", key: "txShieldOICartReference" },
        { label: "Transaction Date", key: "transactionDate" },
        { label: "Card Number", key: "cardno" },
        { label: "Merchant Name", key: "merchantName" },
        { label: "Merchant Reference", key: "merchantRef" },
        { label: "ARN", key: "arn" },
        { label: "Descriptor", key: "paymentDescriptor" },
        { label: "Auth Code", key: "authorizationCode" },
        { label: "Auth Date", key: "authorizationDate" },
        { label: "Auth Amount", key: "authorizationAmount" },
        { label: "Settlement Date", key: "settlementDate" },
        { label: "Settlement Amount", key: "settlementAmount" },
        { label: "Date", key: "dateCreated" },
    ];

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.cart_list")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`txfind_carts_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={t("contents.oi.cart_list")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                loading={isLoading}
                title={() => getHeaderTable()}
                rowKey="txShieldOICartReference"
                columns={getColumnSource()}
                size="small"
                scroll={{ x: getTableWidth() }}
                className="table-v3 custom-pagination"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    ) : null;
};

export default CartList;