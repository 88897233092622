import React, { useState, useEffect } from "react";
import { API_3DS_TX } from "../../../config/config";
import { Modal, Button, Form, Input, Select, Switch } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

const { Option } = Select;

const BinMidModal = (props) => {
    const { t, binmidId, isModalVisible, handleCancel, siteList, fetchBinMid } = props;
    const [binmid, setBinMid] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [siteList, setSiteList] = useState(null);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    // Commented out by Jericho 2023/05/19 as it is not used
    // const tailLayout = {
    //     wrapperCol: { offset: 8, span: 16 },
    // };

    const [form] = Form.useForm();

    const card_type = [
        {name:'Visa', value:'Visa'},
        {name:'Mastercard', value:'Mastercard'},
        {name:'AMEX', value:'AMEX'},
        // {name:'Discover', value:'Discover'},
        // {name:'Diners', value:'Diners'},
        {name:'CUP', value:'CUP'},
        {name:'JCB', value:'JCB'},
        // {name:'Maestro', value:'Maestro'},
    ];

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const formValChange = (chgV, allV) => {
        setBinMid({ ...binmid, ...allV });
    };

    useEffect(() => {
        const getBinMidDetail = () => {
            const apiUrl = API_3DS_TX + `/binmid/${binmidId}/details`;
            axios
                .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
                .then(({ data }) => {
                    setBinMid(data);
                    form.setFieldsValue(data);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: error.response ? error.response.data.msg : "",
                        showConfirmButton: true,
                    });
                });
        };

        if (binmidId) {
            getBinMidDetail();
        }
        // if(!siteList) {
        //     getSiteList();
        // }
    }, [binmidId, form, t]); 

    // const getSiteList = () => {
    //     axios
    //         .post(
    //             SIDS_URL,
    //             {},
    //             { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
    //         )
    //         .then(({ data }) => {
    //             setSiteList(data);
    //         })
    //         .catch((error) => {
    //             Swal.fire({
    //                 icon: "error",
    //                 title: t("login.dialogs.catch"),
    //                 text: error.response ? error.response.data.msg : "",
    //                 showConfirmButton: true,
    //             });
    //         });
    // };

    const onFinish = async () => {
        const apiUrl = API_3DS_TX + `/binmid/${binmidId}/update`;

        await axios
            .post(apiUrl, binmid, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                if (data.status.toLowerCase() === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.3dsecure.binmid.updated"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchBinMid();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
        setLoading(false);
    };

    const isOkButtonProps = () => !binmid || !binmid.bin || loading;

    return (
        <>
            <Modal
                title={t("contents.3dsecure.view_binmid")}
                visible={isModalVisible}
                width={600}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.3dsecure.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.3dsecure.form.submitting") : t("contents.3dsecure.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="viewBinMid" onValuesChange={formValChange}>
                    <Form.Item name="id" label={t("contents.3dsecure.form.binmidID")}>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item name="bin" label={t("contents.3dsecure.form.bin")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="mid" label={t("contents.3dsecure.form.mid")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="mcc" label={t("contents.3dsecure.form.mcc")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="card_type" label={t("contents.3dsecure.form.card_type")}>
                        <Select placeholder={t("contents.3dsecure.form.please_select")}>
                            {card_type
                                ? card_type.map((item) => (
                                        <Option key={item.value} value={item.value}>
                                            {item.name}
                                        </Option>
                                    ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="sid" label={t("contents.3dsecure.form.site_name")} rules={[{ required: true }]}>
                        <Select
                                showSearch
                                showArrow="true" 
                                placeholder={t("contents.3dsecure.form.please_select")}
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}>
                            {siteList
                                ? siteList.map((item) => (
                                        <Option key={item.sid} value={item.sid}>
                                            {item.name}
                                        </Option>
                                    ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        key="active"
                        name="active"
                        label={t("contents.3dsecure.form.status")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default BinMidModal;
