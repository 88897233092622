import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import PermissionsRequired from "../../helper/PermissionsRequired";
import CreateMerchant from "./CreateMerchant";
import MerchantModal from "./Merchant";
import moment from "moment";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import AssociationsChecker from "./../../helper/AssociationsChecker";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";

const DATA_URL = "/api/v1/txshield/merchants";
const { Option } = Select;

const MerchantList = (props) => {
    const [{ data, totalResult, isLoading, requestData }, doFetch, setRequestData] = DataFetchHookOI(DATA_URL, "POST", null, {
        page: 1,
        size: 20,
        order: "ASC",
    }); // eslint-disable-line
    const [formData, setFormData] = useState({ hideColumn: [], associatedMerchantIds: [] });
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [merchant, setMerchant] = useState(null);
    const [sortedInfo, setSortedInfo] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const oiAssocication = localStorage.getItem("oiAssocication") ? localStorage.getItem("oiAssocication").split(",") : [];
    const { t } = props;
    const initFormData = {
        field: "name",
        keyword: null,
        order: "ASC",
        associatedMerchantIds: oiAssocication,
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const showMerchant = (merchantId) => {
        setMerchant(merchantId);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const columns = [
        {
            dataIndex: "name",
            key: "name",
            title: t("tables.fields.merchantname"),
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name)
            },
            sortOrder: SortOrder(sortedInfo, "name"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" onClick={() => showMerchant(record.merchantId)} style={{ marginLeft: "auto" }}>
                    <FileSearchOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }} />
                </Button>
            ),
        },
    ];

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                keyword: formData.keyword ? formData.keyword.trim() : null,
                field: formData.field,
               // order: formData.order,
                associatedMerchantIds: formData.oiAssocication,
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: null, endDate: null, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t('tables.actions.search')} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }} xxl={{ span: 4 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t('tables.fields.field')}>
                                    <Option value="name">{t("tables.fields.merchantname")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 9 }} xxl={{ span: 10 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t('tables.fields.keyword')} />
                            </Form.Item>
                        </Col>
                        {/*
                        <Col className="gutter-row" span={6}>
                            <Form.Item className="gutter-row" name="order">
                                <Select style={{ width: "100%" }} placeholder="Order">
                                    <Option value="ASC">ASC</Option>
                                    <Option value="DESC">DESC</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                    
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6, offset: 0 }} xl={{ span: 3, offset: 3 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t('tables.actions.clear_all')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4}}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t('tables.actions.search')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const fetchMerchants = () => doFetch(OI_URL + DATA_URL + `?` + moment().unix());

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.merchants")}</h3>
            <CreateMerchant t={t} fetchMerchants={fetchMerchants} />
        </div>
    );
    

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={t("contents.oi.merchants")}></PageHeader>
            {AdvancedSearchForm()}
            <MerchantModal t={t} merchantId={merchant} isModalVisible={isModalVisible} handleCancel={handleCancel} fetchMerchants={fetchMerchants} />
            <Table
                loading={isLoading}
                columns={columns}
                title={() => getHeaderTable()}
                rowKey="merchantId"
                className="table-v3 custom-pagination"
                size="small"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    ) : null;
};

export default MerchantList;